<template>
  <div>
    <object class="pr-2 md:pr-4 lg:pr-8">
      <embed :src="require(`@/assets/pdf/1.pdf`)" width="100%" height="800"/>
    </object>
    <SendQ />
    <h2 class="text-2xl pb-8 font-medium text-gray-800 mx-auto text-left">Ассоциированные материалы</h2>
    <div class="flex flex-no-wrap sm:flex-wrap">
      <TestCard 
        title="Название теста" 
        description="Фамилия Имя" 
        link="/testpage"
        :pointsGot='0' 
        :pointsTotal='10' 
        :haveBeenDone='false' 
      />
      <VideoCard 
        title="Название видео" 
        description="Фамилия Имя" 
        link="/videos/1"
        :isSaved='true'
        :isBlocked='false' 
        type="single"
      />
    </div>
  </div>
</template>

<script>
import TestCard from '@/components/tests/TestCard.vue'
import VideoCard from '@/components/videos/VideoCard.vue'
import SendQ from '../../components/SendQ.vue'

export default {
  name: 'PublicationPage',
  components: {
    TestCard,
    VideoCard,
    SendQ
  }
}
</script>

<style>
</style>
